<template>
  <vx-card>
    <div class="configure-dialog">
      <testing-banner
        v-if="isTestsPresent && isManyTestsEnabled && isLoadingComplete"
        class="configure-dialog__testing-banner"
        :class="{
          _danger: !isShowTestActiveBanner,
          _default: isShowTestActiveBanner
        }"
        @on-action-click="onTestingBannerActionClick"
      >
        <template>{{ testBannerMessage }}</template>
        <template #link> {{ $t('routes.abtesting') }}</template>
      </testing-banner>
      <form-wizard
        color="rgba(var(--vs-primary), 1)"
        title=""
        subtitle=""
        :nextButtonText="$t('vue.next')"
        :backButtonText="$t('vue.back')"
        :finishButtonText="$t('inputs.submit')"
        ref="formWizard"
        shape="circle"
        @on-complete="onComplete"
        @on-change="onStepChanged"
        v-if="dialog && dialogId"
      >
        <!-- tab 1 content -->
        <tab-content :title="$t('vue.prepareWebsite')" class="mb-5" icon="feather icon-home" :before-change="isStep1Validated" :lazy="true">
          <step-one :info-img="infoImg" :dialog-id="dialogId"></step-one>
        </tab-content>

        <!-- tab 2 content -->
        <tab-content :title="$t('vue.displayOptions')" class="tab2 mb-5" icon="feather icon-briefcase" :before-change="isStep2Validated" :lazy="true">
          <step-two
            :info-img="infoImg"
            :dialog-id="dialogId"
            :leftScreenVSWidth="leftScreenVSWidth"
            :rightScreenVSWidth="rightScreenVSWidth"
            :button-container-min-width="buttonContainerMinWidth"
            :video-client-height="videoClientHeight"
            :video-client-width="videoClientWidth"
            :video-key="videoKey"
            @dialog-updated="onDialogUpdated"
          ></step-two>
        </tab-content>

        <!-- tab 3 content -->
        <tab-content
          :title="$t('vue.textDialogtype')"
          class="mb-5"
          icon="feather icon-image"
          :before-change="isStep3Validated"
          v-if="dialog.design === 'pathadvice'"
        >
          <step-three :info-img="infoImg" :dialog-id="dialogId" :lazy="true"></step-three>
        </tab-content>

        <!-- tab 4 content -->
        <tab-content
          :title="$t('vue.requestOptions')"
          class="mb-5"
          icon="feather icon-phone"
          v-if="dialog.design === 'pathadvice'"
          :lazy="true"
          :before-change="isStep4Validated"
        >
          <step-four :info-img="infoImg" :dialogId="dialogId"></step-four>
        </tab-content>

        <!-- tab 5 content -->
        <tab-content
          :title="$t('vue.endScreen')"
          class="mb-5"
          icon="feather icon-star"
          v-if="dialog.design === 'pathadvice'"
          :lazy="true"
          :before-change="isStep5Validated"
        >
          <step-five :info-img="infoImg" @dialog-updated="onDialogUpdated"></step-five>
        </tab-content>

        <!-- tab 6 content -->
        <tab-content
          :title="$t('vue.contactForm')"
          class="mb-5"
          icon="feather icon-message-square"
          v-if="dialog.design === 'pathadvice' && !(!HAS_CONTACTFORM_ACCESS && isMobileApp)"
          :lazy="true"
          :before-change="isStep6Validated"
        >
          <step-six :info-img="infoImg"></step-six>
        </tab-content>
      </form-wizard>
    </div>
  </vx-card>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { Device } from '@capacitor/device'

import StepOne from './StepOne.vue'
import StepTwo from './StepTwo.vue'
import StepThree from './StepThree.vue'
import StepFour from './StepFour.vue'
import StepFive from './StepFive.vue'
import StepSix from './StepSix.vue'

import TestingBanner from '@/components/banners/TestingBanner.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

export default {
  components: {
    FormWizard,
    TabContent,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    TestingBanner
  },
  watch: {
    vidoeClientUpdateKey() {
      this.videoClientHeight = document.getElementsByClassName('video-item').length > 0 ? document.getElementsByClassName('video-item')[0].clientHeight : 75
      this.videoClientWidth = document.getElementsByClassName('video-item').length > 0 ? document.getElementsByClassName('video-item')[0].clientHeight : 75
    },

    'dialog.confirmButtonText'() {
      if (this.dialog && this.dialog.confirmButtonText.length > 20) {
        const confirmButtonText = this.dialog.confirmButtonText.substring(0, 20)
        this.UPDATE_DIALOG({
          ...this.dialog,
          confirmButtonText
        })
      }
    },
    'dialog.cancelButtonText'() {
      if (this.dialog && this.dialog.cancelButtonText.length > 20) {
        const cancelButtonText = this.dialog.cancelButtonText.substring(0, 20)
        this.UPDATE_DIALOG({
          ...this.dialog,
          cancelButtonText
        })
      }
    }
  },
  data() {
    return {
      isMobileApp: false,
      hasCompletedFormSubmission: false,
      isLoadingComplete: false,
      leftScreenVSWidth: 7,
      rightScreenVSWidth: 5,
      dialogId: null,
      loading: false,
      isDefaultPhoto: false,
      showImage: false,
      uploadingVideo: false,
      videoURL: null,
      transcodedURL: null,
      transcodingVideo: false,
      displayedMessage: false,
      switchVisibility: true,
      videoDialogImg: require('@/assets/images/elements/video.svg'),
      pictureDialogImg: require('@/assets/images/elements/picture.svg'),
      videoDialogImgSelected: require('@/assets/images/elements/videoSelected.svg'),
      pictureDialogImgSelected: require('@/assets/images/elements/pictureSelected.svg'),
      backgroundImg: require('@/assets/images/elements/dialog-background.svg'),
      infoImg: require('@/assets/images/elements/info.svg'),

      videoKey: Math.random().toString(36).substring(2, 15),

      buttonContainerMinWidth: 0,
      videoClientHeight: 75,
      videoClientWidth: 75,
      vidoeClientUpdateKey: Math.random().toString(36).substring(2, 15),
      activateAllSteps: true,

      tests: []
    }
  },
  created() {
    this.checkIsMobileApp()

    this.$store.commit('UPDATE_DIALOG', null)
  },
  async mounted() {
    const vm = this

    try {
      await vm.$vs.loading()

      vm.windowResize()

      const supportsOrientationChange = 'onorientationchange' in window
      const orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize'
      if (orientationEvent === 'orientationchange') {
        window.addEventListener(
          orientationEvent,
          function () {
            vm.buttonContainerMinWidth = 0
            setTimeout(async () => {
              vm.windowResize()
            }, 1000)
          },
          false
        )
      }

      window.addEventListener('resize', vm.windowResize)

      vm.dialogId = vm.$route.params.id
      await vm.getDialogInfo(vm.dialogId)

      await vm.getAllTests()

      this.isLoadingComplete = true

      if (this.activateAllSteps) {
        const wizard = this.$refs.formWizard
        wizard.activateAll()
      }
      this.$serverBus.$on('toggle-use-one-media-for-all-steps', (val) => {
        this.$store.commit('UPDATE_DIALOG', {
          ...this.dialog,
          useOneMediaForAllSteps: val
        })
      })
      this.$serverBus.$on('update-dialog-show-media', (show) => {
        this.$store.commit('UPDATE_DIALOG', {
          ...this.dialog,
          showDefaultMedia: show
        })
      })
    } catch (error) {
      /* eslint-disable no-console */
      console.log(error.message)
    }

    await vm.$vs.loading.close()
  },
  beforeDestroy() {
    this.$serverBus.$off('update-dialog-show-media')
    this.$serverBus.$off('toggle-use-one-media-for-all-steps')
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      dialog: 'dialog',
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      selectedWidgetTranslation: 'widgetTranslation/selectedWidgetTranslation',
      hasWidgetTranslationUpdated: 'widgetTranslation/hasWidgetTranslationUpdated'
    }),
    HAS_CONTACTFORM_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('contact-form') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    HAS_LEADOVERVIEW_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('contact-form') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    locale() {
      return this.$i18n.locale
    },

    isTestsPresent() {
      if (this.tests && this.tests.length) {
        return true
      }
      return false
    },

    isDefaultTestPresent() {
      if (this.isTestsPresent) {
        return this.tests.find((el) => el.default)
      }
      return false
    },

    isManyTestsEnabled() {
      if (this.isTestsPresent) {
        const manyTests = this.tests.filter((el) => el.enabled && !el.default)

        return manyTests && manyTests.length >= 1
      }
      return false
    },

    isDefaultAndManyTestsEnabled() {
      if (this.isTestsPresent && this.isDefaultTestPresent) {
        const isDefaultTestEnabled = this.isDefaultTestPresent.enabled

        if (isDefaultTestEnabled && this.isManyTestsEnabled) {
          return true
        }
      }
      return false
    },

    isShowTestActiveBanner() {
      if (this.isTestsPresent) {
        return this.isDefaultAndManyTestsEnabled
      }
      return false
    },
    testBannerMessage() {
      if (this.isShowTestActiveBanner) {
        return this.$t('banners.abTestingActive')
      }
      return this.$t('banners.abTestingDisabled')
    }
  },
  methods: {
    ...mapMutations(['UPDATE_DIALOG']),
    ...mapActions({
      updateDialogPartial: 'updateDialogPartial',
      setHasWidgetTranslationUpdated: 'widgetTranslation/setHasWidgetTranslationUpdated'
    }),

    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
        }
      } catch (error) {
        console.log(error)
      }
    },

    onTranscodedMediaUpdated(updatedMedia) {
      const mediaToUpdate = {}
      if (updatedMedia.allMedia) {
        mediaToUpdate.allMedia = updatedMedia.allMedia
      }
      if (updatedMedia.buttonMedia) {
        mediaToUpdate.buttonMedia = updatedMedia.buttonMedia
      }
      if (updatedMedia.smallButtonMedia) {
        mediaToUpdate.smallButtonMedia = updatedMedia.smallButtonMedia
      }
      if (updatedMedia.welcomeScreenMedia) {
        mediaToUpdate.welcomeScreenMedia = updatedMedia.welcomeScreenMedia
      }
      if (updatedMedia.buttonPlusTextNewMedia) {
        mediaToUpdate.buttonPlusTextNewMedia = updatedMedia.buttonPlusTextNewMedia
      }
      this.$store.commit('UPDATE_DIALOG', {
        ...this.dialog,
        ...mediaToUpdate
      })
    },
    onTestingBannerActionClick() {
      this.$router.push(`/ab-testing/${this.dialogId}`)
    },
    async getAllTests() {
      this.tests = []

      try {
        const abTests = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').where('company', '==', this.dialog.company).get()

        abTests.docs.forEach(async (doc) => {
          const test = doc.data()

          if (test.default) {
            this.tests.push(test)
          } else {
            test.default = false
            this.tests.push(test)
          }
        })
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },
    async onStepChanged() {
      if (!this.hasCompletedFormSubmission) {
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
        await this.save()
      }
    },
    translate(code) {
      return this.$i18n.t(code)
    },
    async getDialogInfo(dialogId) {
      const dialogRef = await this.$db.collection('dialogs').doc(dialogId).get()
      if (dialogRef && dialogRef.data()) {
        const dialog = dialogRef.data()

        if (dialog) {
          if (!dialog.created) {
            dialog.created = new Date()
          }
          if (!dialog.showVideo) {
            dialog.showVideo = false
          }
          if (!dialog.design) {
            dialog.design = 'pathadvice'
          }

          if (!dialog.type || dialog.type === 'popup') {
            dialog.type = 'button-card'
          }
          if (!dialog.createdBy) {
            dialog.createdBy = this.activeUserInfo.uid
          }

          /* STEP 2 Properties */
          if (typeof dialog.isMoreInformationEnabled === 'undefined') {
            dialog.isMoreInformationEnabled = false
          }

          if (typeof dialog.isAlwaysShowButtonEnabled === 'undefined') {
            dialog.isAlwaysShowButtonEnabled = false
          }

          if (typeof dialog.isMinimizeSmallButtonEnabled === 'undefined') {
            dialog.isMinimizeSmallButtonEnabled = false
          }

          if (typeof dialog.isSmallButtonPreviewVisible === 'undefined') {
            dialog.isSmallButtonPreviewVisible = false
          }

          if (typeof dialog.showDefaultMedia === 'undefined') {
            dialog.showDefaultMedia = true
          }

          if (!dialog.textStep2) {
            dialog.textStep2 = this.translate('vue.textStep2DefaultText')
          }
          if (!dialog.step2ButtonColor) {
            dialog.step2ButtonColor = '#3B86F7'
          }
          if (!dialog.step2TextColor) {
            dialog.step2TextColor = '#ffffff'
          }
          if (!dialog.step2IconColor) {
            dialog.step2IconColor = '#ffffff'
          }

          if (typeof dialog.buttonPlusTextNewMediaIconColor === 'undefined') {
            dialog.buttonPlusTextNewMediaIconColor = '#3034F7'
          }

          if (!dialog.confirmButtonColor) {
            dialog.confirmButtonColor = '#238000'
          }
          if (!dialog.confirmButtonTextColor) {
            dialog.confirmButtonTextColor = '#ffffff'
          }
          /* STEP 2 Properties */

          if (dialog && typeof dialog.sloganTitle === 'undefined') {
            dialog.sloganTitle = "Let's Connect!"
          }

          if (!dialog.waitingRoomMessage) {
            dialog.waitingRoomMessage = this.translate('vue.waitingRoomDefaultMessage')
          }

          if (!dialog.leadForm) {
            dialog.leadForm = {
              enableLeadFormBeforeConversation: false,
              isPrivacyInformationEnabled: false,
              privacyInformationHtml: this.translate('vue.privacyInformationMessage')
            }
          }

          if (dialog.leadForm && typeof dialog.leadForm.privacyInformationHtml === 'undefined') {
            dialog.leadForm.privacyInformationHtml = this.translate('vue.privacyInformationMessage')
          }

          /* STEP 4 Waiting room content chat message properties */
          if (typeof dialog.waitingRoomContentChatMessage === 'undefined') {
            dialog.waitingRoomContentChatMessage = {
              backgroundColor: '#C4C4C4',
              fontColor: '#12598d'
            }
          }
          /* STEP 4 Waiting room content chat message properties */

          if (typeof dialog.sessionEndScreen === 'undefined') {
            dialog.sessionEndScreen = {
              titleClosingMessage: this.translate('vue.sessionEndScreenDefaultTitle'),
              copyClosingMessage: this.translate('vue.sessionEndScreenDefaultCopy'),
              backgroundColor: '#3B86F7',
              fontColor: '#FFFFFF'
            }
          }

          if (typeof dialog.isShowFeedbackForm === 'undefined') {
            dialog.isShowFeedbackForm = false
          }

          if (typeof dialog.isSendFeedbackViaMessage === 'undefined') {
            dialog.isSendFeedbackViaMessage = false
          }

          if (typeof dialog.feedbackFormQuestionFirst === 'undefined') {
            dialog.feedbackFormQuestionFirst = ''
          }

          if (typeof dialog.feedbackFormQuestionSecond === 'undefined') {
            dialog.feedbackFormQuestionSecond = ''
          }

          if (typeof dialog.feedbackFormQuestionThird === 'undefined') {
            dialog.feedbackFormQuestionThird = ''
          }

          if (typeof dialog.contactForm === 'undefined') {
            this.activateAllSteps = false
            dialog.contactForm = {
              message: this.translate('vue.contactFormText'),
              backgroundColor: '#3B86F7',
              fontColor: '#FFFFFF',
              isBookingEnabled: false,
              selectedAppointment: null,
              fields: {
                name: {
                  enabled: true,
                  required: true
                },
                email: {
                  enabled: true,
                  required: true
                },
                phone: {
                  enabled: true,
                  required: true
                },
                message: {
                  enabled: true,
                  required: true
                },
                date: {
                  enabled: false,
                  required: false
                },
                country: {
                  enabled: false,
                  required: false
                },
                company: {
                  enabled: false,
                  required: false
                }
              },
              sendMessageTitle: this.translate('vue.sendMessageDefaultTitle'),
              sendMessageCopy: this.translate('vue.sendMessageDefaultCopy'),
              bookingSlotTitle: this.translate('vue.bookingSlotDefaultTitle'),
              bookingSlotCopy: this.translate('vue.bookingSlotDefaultCopy'),
              isPrivacyInformationEnabled: false,
              isDefaultContactFormInsideChat: false,
              privacyInformationHtml: ''
            }
          }

          /* Country and Company fields added to existing widgets if not present */
          if (dialog.contactForm && dialog.contactForm.fields && !dialog.contactForm.fields.country) {
            dialog.contactForm.fields.country = {
              enabled: false,
              required: false
            }
          }
          if (dialog.contactForm && dialog.contactForm.fields && !dialog.contactForm.fields.company) {
            dialog.contactForm.fields.company = {
              enabled: false,
              required: false
            }
          }

          if (typeof dialog.automatedChatResponse === 'undefined') {
            dialog.automatedChatResponse = this.translate('webConnect.automatedChatResponse')
          }
          if (typeof dialog.isAutomatedChatResponseEnabled === 'undefined') {
            dialog.isAutomatedChatResponseEnabled = false
          }
          /*
          Privacy Information added in later stage of development, so adding this check here
        */
          if (typeof dialog.contactForm.isPrivacyInformationEnabled === 'undefined') {
            dialog.contactForm.isPrivacyInformationEnabled = false
            dialog.contactForm.privacyInformationHtml = ''
          }
        }

        dialog.default = true
        dialog.companyDisplayName = dialog.companyDisplayName || (this.company && this.company.companyDisplayName) || ''

        this.$store.commit('UPDATE_DIALOG', dialog)
      }
    },
    onDialogUpdated(dialog) {
      this.$store.commit('UPDATE_DIALOG', dialog)
    },
    windowResize() {
      let w = window.innerWidth
      if (window.innerWidth !== undefined && window.innerHeight !== undefined) {
        w = window.innerWidth
      } else {
        w = !!document && !!document.documentElement ? document.documentElement.clientWidth : w
      }
      let newW = w * 0.5833333333333333
      this.leftScreenVSWidth = 7
      this.rightScreenVSWidth = 5
      if (w > 1200) {
        newW = newW - 280
        this.buttonContainerMinWidth = newW
      } else if (w >= 769 && w < 990) {
        this.buttonContainerMinWidth = w - 200
      } else if (w > 768 && w < 1200) {
        newW = newW - 120
        this.buttonContainerMinWidth = newW
      } else {
        this.buttonContainerMinWidth = w - 200
      }
    },

    async isStep1Validated() {
      const validated = await this.validateStep1()
      if (validated) {
        this.logAnalyticsEvent(this.$AnalyticsEvents.WEB_CONNECT_PREPARE_WEBSITE, {
          dialogId: this.dialogId
        })
      }
      return validated
    },

    async isStep2Validated() {
      const validated = await this.validateStep2()
      if (validated) {
        this.logAnalyticsEvent(this.$AnalyticsEvents.WEB_CONNECT_DISPLAY_OPTIONS, {
          dialogId: this.dialogId
        })
      }
      return validated
    },

    async isStep3Validated() {
      const validated = await this.validateStep3()
      if (validated) {
        this.logAnalyticsEvent(this.$AnalyticsEvents.WEB_CONNECT_TEXT_COLORS, {
          dialogId: this.dialogId
        })
      }
      return validated
    },

    async isStep4Validated() {
      const validated = await this.validateStep4()
      if (validated) {
        this.logAnalyticsEvent(this.$AnalyticsEvents.WEB_CONNECT_REQUEST_OPTIONS, {
          dialogId: this.dialogId
        })
      }
      return validated
    },

    async isStep5Validated() {
      const validated = await this.validateStep5()
      if (validated) {
        this.logAnalyticsEvent(this.$AnalyticsEvents.WEB_CONNECT_SESSION_END_SCREEN, {
          dialogId: this.dialogId
        })
      }
      return validated
    },

    async isStep6Validated() {
      const validated = await this.validateStep6()
      if (validated) {
        this.logAnalyticsEvent(this.$AnalyticsEvents.WEB_CONNECT_CONTACT_FORM, {
          dialogId: this.dialogId
        })
      }
      return validated
    },

    async validateStep1() {
      if (this.dialog.design === 'custom') {
        return true
      }

      const validateDialogName = await this.$validator.validate('dialog-name')
      const validateWidgetUse = await this.$validator.validate('widget-use')
      if (!validateDialogName || !validateWidgetUse) {
        return false
      }

      setTimeout(() => {
        this.vidoeClientUpdateKey = Math.random().toString(36).substring(2, 15)
      }, 200)
      return true
    },
    validateStep2() {
      return true
    },
    validateStep3() {
      this.$serverBus.$emit('validate-step-3')
      if (this.dialog.type === 'button' && this.dialog.text.length > 300) {
        return false
      }

      if (this.dialog.isMoreInformationEnabled) {
        const validateNotEmpty =
          typeof this.dialog.moreInformationLink !== 'undefined' && this.dialog.moreInformationLink && this.dialog.moreInformationLink.length > 0

        const value = this.dialog.moreInformationLink

        if (validateNotEmpty && value && value.toLowerCase().startsWith('tel:')) {
          const index = value.toLowerCase().indexOf('tel:')
          const tel = value.substring(index + 4)
          return tel.length >= 10 && tel.length <= 20
        }

        if (validateNotEmpty && value && value.toLowerCase().startsWith('mailto:')) {
          const index = value.toLowerCase().indexOf('mailto:')
          const email = value.substring(index + 7)
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(String(email).toLowerCase())
        }

        const validateURL = typeof value !== 'undefined' && value && /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(value)
        return validateNotEmpty && validateURL
      }

      return true
    },

    validateStep4() {
      return true
    },

    validateStep5() {
      this.$serverBus.$emit('validate-step-5')
      if (this.dialog.isShowFeedbackForm) {
        const validateNotEmpty = this.dialog.feedbackFormQuestionFirst || this.dialog.feedbackFormQuestionSecond || this.dialog.feedbackFormQuestionThird
        return !!validateNotEmpty
      }
      if (
        this.dialog.isShowFeedbackForm &&
        this.selectedLanguageForWebConnect &&
        this.selectedLanguageForWebConnect.code !== 'en' &&
        this.selectedWidgetTranslation
      ) {
        const validateNotEmpty =
          this.selectedWidgetTranslation.feedbackFormQuestionFirst ||
          this.selectedWidgetTranslation.feedbackFormQuestionSecond ||
          this.selectedWidgetTranslation.feedbackFormQuestionThird
        return !!validateNotEmpty
      }
      return true
    },

    validateStep6() {
      return true
    },

    async onComplete() {
      this.hasCompletedFormSubmission = true
      await this.save()
      this.$serverBus.$emit('new-dialog-added')
      setTimeout(() => {
        this.$vs.notify({
          title: this.translate('vue.success'),
          text: this.translate('vue.dialogSuccessfullySaved'),
          color: 'success',
          iconPack: 'feather',
          position: 'bottom-center',
          icon: 'icon-check-circle'
        })
        this.$router.push({ name: 'dialog' })
      }, 300)
    },

    async saveTranslation() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code && this.dialogId && this.company && this.company.id) {
        const webConnect = this.selectedWidgetTranslation
        const _update = {
          canBeDeleted: true,
          ln: this.selectedLanguageForWebConnect.code,
          lnFullText: this.selectedLanguageForWebConnect.lnFullText,
          lnText: this.selectedLanguageForWebConnect.lnText,
          modified: new Date(),
          sort: 1
        }
        _update.webConnect = webConnect

        await this.$db
          .collection('translations')
          .doc(this.company.id)
          .collection('web-connect')
          .doc(this.dialogId)
          .set({ companyId: this.company.id, updated: new Date() }, { merge: true })

        await this.$db
          .collection('translations')
          .doc(this.company.id)
          .collection('web-connect')
          .doc(this.dialogId)
          .collection('languages')
          .doc(this.selectedLanguageForWebConnect.code)
          .set(_update, { merge: true })
        /* As the translation is saved to firestore, set the below store prop to false */
        this.setHasWidgetTranslationUpdated(false)
      }
    },

    async save() {
      this.$vs.loading()
      this.loading = true
      const dialog = { ...this.dialog }
      if (this.dialog) {
        delete dialog.videoURL
        delete dialog.transcodedURL
        delete dialog.b2bOnly
      }
      this.dialogUpdated = new Date()
      await this.$db.collection('dialogs').doc(this.dialogId).set(dialog, { merge: true })
      if (this.hasWidgetTranslationUpdated) {
        await this.saveTranslation()
      }
      this.loading = false
      setTimeout(() => {
        this.$vs.loading.close()
      }, 300)
    },
    updateConfirmButtonColor(color) {
      this.$store.commit('UPDATE_DIALOG', {
        ...this.dialog,
        confirmButtonColor: color.hex
      })
    },
    updateConfirmButtonColorText(color) {
      this.$store.commit('UPDATE_DIALOG', {
        ...this.dialog,
        confirmButtonTextColor: color.hex
      })
    }
  }
}
</script>
<style lang="scss">
@import './configure-dialog.scss';
</style>
